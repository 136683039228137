<template>
  <div class="minbox">
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="boxContentBg">
      <div class="banner">
        <div class="bannerTitle">
          机构间场外交易平台
        </div>
        <div class="seeMore">
          基于区块链技术的安全且合规的机构间场外交易平台
        </div>
      </div>
    </div>
    <!-- 就业机会 -->
    <div class="boxContentTrem">
      <div class="opportunity">
        <div class="opportunityTitle">
          平台介绍
        </div>
        <div class="opportunityDescride">
          <div>
            机构间场外市场的交易长期发生在交易员的私人社交软件好友圈中，这种无组织、非标准化、不规范、不受监管、随意性强的交易模式，往往带来诸如黄牛、
          </div>
          <div>
            无照中介、萝卜章、临时工的问题，甚至成为老鼠仓与利益输送的犯罪温床。
          </div>
          <div>
            “机构间的交易要在基于机构利益的专属交易工具上完成”，打造一个基于区块链技术的安全且合规的机构间场外交易平台，便成了我们的重点工作。应用区
          </div>
          <div>块链，我们发展了以下的交易场景：</div>
        </div>
        <div class="opportunityContnets">
          <div class="opportunityContnetItem ContnetItemsAlone1">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/transaction_intro_01@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">机构认证</div>
            <div class="opportunityContnetItemLine"></div>
            <div class="opportunityContnetTrem">
              不再有一个集中的交易对手的认证中心，而是按照各个节点发生的实际业务进行认证，再由点而线、由线而面扩展，最终平台上所有的交易对手均是根据多个机构发生过真实交易后得到的认证。
            </div>
          </div>
          <div class="opportunityContnetLine"></div>
          <div class="opportunityContnetItem ContnetItemsAlone3">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/transaction_intro_02@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">真实交易</div>
            <div class="opportunityContnetItemLine"></div>
            <div class="opportunityContnetTrem">
              交易确认后双方不能反悔、要素不能窜改；智能合约不仅包含交易要素，还包含询议价过程；电子合同作为智能合同的一部份加以保存。
            </div>
          </div>
          <div class="opportunityContnetLine"></div>
          <div class="opportunityContnetItem ContnetItemsAlone5">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/transaction_intro_03@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">合规监管</div>
            <div class="opportunityContnetItemLine"></div>
            <div class="opportunityContnetTrem">
              透过人工智能技术剥离“钓鱼”报价，同时可在每个交易当下的市场盘面生成智能合约，事后可溯，便于侦测老鼠仓与利益输送等行为
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 对话 dialogue  -->
    <div class="boxContentDialogue">
      <div class="dialogue">
        <div class="expertDialogue">
          您是否希望和我们的专家对话？
        </div>
        <div class="technicalProposal">
          联系我们获得更多技术解决方案
        </div>
        <div class="contactUs">
          <div class="contactUsTitle">联系我们</div>
          <div class="contactUsImages">
            <img
              src="../../assets/homePageImg/home_banner_more@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
export default {
  data() {
    return {}
  },
  components: {
    Navbar,
    FooterAll,
  },
  created() {
    this.activeIndex = this.$route.query.id
  },
}
</script>
<style lang="scss" scoped>
@import './currency.scss';
.boxContentBg {
  width: 100%;
  background: url('../../assets/platformServices/transaction_banner.png')
    no-repeat center center !important;
}
.seeMore {
  height: 33px !important;
}
.boxContentTrem {
  height: 729px !important;
  background: #fbfbfb !important;
}
.opportunityContnets {
  width: 1180px;
  margin-bottom: 40px;
  background: #ffffff;
  margin-top: 30px;
  display: flex;
  align-items: center;
  height: 414px;
  .opportunityContnetItem {
    width: 334px !important;
    &:hover {
      .opportunityContnetItemTitle{
        color: #0F4994;
      }
      .opportunityContnetTrem{
        color: #666666;
      }
    }
    .opportunityContnetItemImage {
      width: 140px;
      height: 138px;
      // padding: 38px 1px 0 113px;
      padding-top: 38px;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .opportunityContnetItemTitle {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #181818;
      line-height: 28px;
      // padding: 30px 159px 0 143px;
      text-align: center;
      padding-top: 30px;
      // margin: 0 auto;
    }
    .opportunityContnetItemLine {
      width: 21px;
      height: 4px;
      background: #0f4790;
      text-align: center;
      margin: 13px auto;
      margin-bottom: 0px  !important;
    }
    .opportunityContnetTrem {
      width: 100%;
      height: 88px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 22px;
      //padding: 26px 32px 0px 16px;
      padding-top: 26px;
      text-align: center;
    }
  }
  .opportunityContnetLine {
    width: 1px;
    height: 300px;
    background: #eeeeee;
  }
  .ContnetItemsAlone3 {
    width: 350px !important;
    margin: 0 32px;
    .opportunityContnetItemImage {
      margin: 0 auto;
    }
    .opportunityContnetItemTitle {
      text-align:  center;
    }
    .opportunityContnetItemLine {
      margin: 0 auto;
      margin-top: 12px;
    }
    .opportunityContnetTrem {
      width: 100%;
    }
  }
  .ContnetItemsAlone5 {
    margin: 0 16px 0 32px;
    .opportunityContnetItemImage {
      margin: 0 auto;
    }
    .opportunityContnetItemTitle {
      // padding-left: 159px;
      // padding-right: 143px;
      text-align: center;
    }
    .opportunityContnetItemLine {
      text-align: center;
    }
    .opportunityContnetTrem {
      width: 100%;
    }
  }
  .ContnetItemsAlone1 {
    padding: 0 32px 0 16px;
  }
}
</style>
